import { useProductsStore } from '../stores/productsStore';

export default function waitForProducts(): Promise<void> {
	const ProductsStore = useProductsStore();

	return new Promise((resolve) => {
		const checkInterval = setInterval(() => {
			if (!ProductsStore.isLoading) {
				clearInterval(checkInterval);
				resolve();
			}
		}, 25);
	});
}
